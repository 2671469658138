.video {
    width: 300px;
}

.video-thumbnail {
    font-size: 60px;
    position: absolute;
}

.video-thumbnail-bg {
    background-color: #ecf0f1;
    height: 30px;
    width: 30px;
}

.video-removed-from {
    position: absolute;
    bottom: 5px;
    right: 10px;
    color: white;
    font-size: 10px;
}

.video-poster-wrapper {
    min-height: 167px;
    /* height: 170px; */
    width: 100%;
}

.video-poster-wrapper > img {
    display: block;
    background-color: black;
    height: 170px;
    width: 100%;
}

.video-poster-wrapper > .processing {
    background-color: #000;
    min-height: 167px;
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 2;
}

.video-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.video-title span {
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.video-desc {
    width: 250px;
    height: 43px;
    overflow-y: scroll;
    font-size: 14px;
}

.thumbnail-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.video-desc::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

.video-desc::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.video-footer {
    font-size: 12px;
    height: 30px;
}

.edit-icon {
    top: 0.5rem;
    right: 1rem;
}
