.DeepDiveCategory {
    max-width: 1600px;
}

.TopicToLink {
    padding: 0.5em;
    .btn-sm {
        margin-right: 0.25em;
        margin-bottom: 0.5em;
    }
}
