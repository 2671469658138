html,
body,
#root {
    height: 100%;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
    scroll-behavior: smooth !important;
}

.layout {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-template-rows: auto 1fr auto;
    height: 100vh;
}

@media (max-width: 768px) {
    .layout {
        grid-template-columns: 1fr;
    }
}

.header {
    grid-column: span 2;
    max-height: 60px;
    vertical-align: middle;
    width: auto;
}

.logo {
    font-family: "Montserrat", sans-serif;
    letter-spacing: 3px;
    text-decoration-color: black;
}

@media (max-width: 768px) {
    .header {
        grid-column: 1;
    }
}

.sidebar {
    grid-column: 1;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.sidebar-body {
    max-width: 300px;
    width: 100%;
}

@media (max-width: 768px) {
    .sidebar-body {
        max-width: 350px;
        margin: 0 auto;
    }
}

.active-category > li.pointer {
    text-decoration: underline;
}

.main-container {
    display: grid;
    grid-template-columns: 700px 350px 400px;
    overflow-x: scroll;
    min-height: 100vh;
}

@media (max-width: 768px) {
    .main-container {
        grid-template-columns: auto;
    }
}

.main-container-body {
    grid-column: 1;
    max-width: 700px;
    min-width: 500px;
    padding-bottom: 50px;
}

@media (max-width: 768px) {
    .main-container-body {
        align-self: center;
        justify-self: center;
        max-width: 350px;
        min-width: 350px;
    }
    .main-container-body > h4 {
        text-align: center;
        margin-top: 1em;
        margin-bottom: 1em;
    }
}

.suggested-topics,
.search-publisher {
    width: 300px;
}

@media (min-width: 769px) {
    .suggested-topics {
        margin-right: 3em;
    }
}

.topic {
    position: relative;
    max-width: 675px;
}

.topic-img-div {
    width: 110px;
    height: 110px;
}

.topic-img-div p {
    text-align: center;
    font-size: 11px;
}

.topic-img-div i {
    cursor: pointer;
}

.topic-img {
    display: flex;
    border-radius: 5px;
    background-size: cover;
    height: 95px;
    width: 95px;
}

.topic-no-img {
    display: flex;
    padding: 1em;
    font-size: 10px;
    border: 1px solid grey;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 95px;
    height: 95px;
}

.custom-switch {
    z-index: 0;
}

@media (max-width: 768px) {
    .suggested-topics,
    .search-publisher {
        align-self: center;
        justify-self: center;
    }
}

@media (max-width: 768px) {
    .last-updated {
        display: block;
        width: 100%;
    }
}

.video-list-wrapper,
.article-list-wrapper,
.preview-list-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
}

.article-list-wrapper div {
    max-width: 200px;
    max-height: 255px;
}

.preview-list-wrapper {
    background-color: #282c34;
    padding: 1em 1em 0 1em;
}

/* generic utility components */
.pointer {
    cursor: pointer;
}

.no-pointer {
    cursor: none;
}

.underline {
    text-decoration: underline !important;
}

.no-side-round-border {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.vjs-control-bar {
    z-index: inherit !important;
}

.z-index-0 {
    z-index: 0 !important;
}

.pointer-none {
    pointer-events: none !important;
}

.loading-modal {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
}

.loading-modal .spinner {
    width: 5em;
    height: 5em;
    color: white;
}

.translate-middle {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Allows vertical scrolling in modal */
.modal-body {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
}
