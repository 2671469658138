.pinned-content,
.banned-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: dense;
    gap: 0.125em 0.5em;
}

.item-position-box {
    position: relative;
    height: 15px;
    width: 15px;
    background-color: #e5e5e5;
    border: 0.5px solid #a5b2b3;
}

.winner-ribbon:hover svg path {
    fill: #25a88a;
}

.item-position-box span {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-size: 10px;
    line-height: 13px;
    color: black;
    text-align: center;
}

.pinned-content .publisher-name,
.pinned-content .author-name,
.pinned-content .tweet-img {
    cursor: pointer;
}

.pinned-content .article {
    max-height: 225px;
}

.pinned-content .left {
    grid-column: 1;
    max-width: 180px;
}
.pinned-content .center {
    grid-column: 2;
    max-width: 180px;
}
.pinned-content .right {
    grid-column: 3;
    max-width: 180px;
}

.pinned-content > .satire {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: dense;
    gap: 0.125em 0.5em;
}

.pinned-article {
    display: inline-block;
    flex-basis: 30%;
    max-width: 170px;
}

.left {
    grid-column: 1;
}

.center,
.middle {
    grid-column: 2;
}

.right {
    grid-column: 3;
}

.satire {
    grid-column: auto;
}

@media (max-width: 768px) {
    .pinned-article {
        flex-basis: 100%;
    }
}

.article {
    height: 225px;
    width: 100%;
    /* max-width: 200px; */
    margin: 0 0.25em;
}

.article-header {
    display: flex;
    max-height: 26px;
    padding: 0 1em;
}

.publisher-name {
    flex: 1;
    margin-right: 0.5em;
}

.article-header > span {
    display: inline;
    font-size: 10px;
    line-height: 26px;
}

.article-image-wrapper {
    position: relative;
    height: 100px;
}

.article-image-wrapper .custom-tag-satire {
    background-color: #25a88a;
    color: white;
    font-size: 9px;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
}

.custom-tag-non-satire {
    font-size: 9px;
    padding: 0 0.25em;
    color: white;
    float: right;
}

.article-image-wrapper > a .spinner-border {
    margin: 2em auto;
}

.article-image-wrapper > a > img {
    object-fit: cover;
    height: 100px;
}

.article-title {
    font-size: 10px;
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 70px;
}

.article-category-link > span {
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.article-footer {
    display: flex;
    min-height: 26px;
    /* justify-content: space-between !important; */
    align-items: center !important;
    vertical-align: middle;
}

.article-footer > span:first-of-type:nth-last-of-type(1) {
    margin-left: auto;
}

.article-footer > span {
    font-size: 10px;
}

.circle {
    position: absolute;
    bottom: 5px;
    right: 10px;
    content: "";
    border-color: white;
    border-radius: 50%;
    border-width: 5px;
    height: 10px;
    width: 10px;
}

.tag-left {
    background-color: #0015dd;
}

.tag-center,
.tag-middle {
    background-color: #a013a5;
}

.tag-right {
    background-color: #f82725;
}

.tag-satire {
    background-color: #25a88a;
}

.article-url {
    font-size: 10px;
}

.ban-icon:hover {
    color: #fd5c5a;
}

.no-image {
    position: absolute;
    top: 40px;
    left: 55px;
}

.color-input-wrapper {
    align-items: center;
    justify-content: space-between;
}

.color-input {
    padding: 0;
    height: 30px;
    width: 30px;
    -webkit-appearance: none;
}

#color-input::-webkit-color-swatch-wrapper {
    padding: 0;
}
#color-input::-webkit-color-swatch {
    border: none;
}

.one-column {
    grid-template-columns: 1fr !important;
}
