a.white-text,
a.white-text:hover {
    color: #fff;
    text-decoration: none;
}

.btn-light.bordered {
    background-color: #fff;
    border: 1px solid #212529;
}
.btn-light.bordered > a {
    color: #212529;
}

.article-content {
    display: flex;
    width: 230px;
    height: 270px;
}

.text-uppercase {
    text-transform: uppercase;
}
