.DeepDiveTopic {
    max-width: 1600px;
}

.ContentList {
    .left {
        color: #0015dd;
    }

    .middle {
        color: #a013a5;
    }

    .right {
        color: #f82725;
    }

    .satire {
        color: #25a88a;
    }
}
