.tweet {
    height: 225px;
    // max-width: 200px;
    margin: 0 0.25em;
    width: 100%;

    .header {
        display: flex;
        padding: 0 1em;

        span {
            display: inline;
            font-size: 10px;
        }

        .author-name {
            line-height: 1;
            font-size: 12px;
        }

        .author-username {
            margin-top: -5px;
        }

        .author-avatar {
            display: inline-block;

            img {
                height: 48px;
                width: 48px;
                border-radius: 50%;
            }
        }

        .author-details {
            flex: 2;
            display: inline-flex;
            flex-direction: column;
        }

        .icons-container {
            display: flex;
            flex-direction: column;
            justify-items: center;

            .twitter-icon {
                img {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }

    .tweet-body {
        font-size: 10px;
        overflow-y: scroll;
        height: 70px;
        position: relative;
        margin-bottom: 0.75em;

        .bias-circle {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 1em;
            right: 1.6em;
            border-color: white;
            border-radius: 50%;
            border-width: 5px;
            height: 12px;
            width: 12px;

            span {
                color: white;
                font-size: 8px;
            }
        }

        .left {
            background-color: #0015dd;
        }

        .middle {
            background-color: #a013a5;
        }

        .right {
            background-color: #f82725;
        }
    }

    .tweet-category-link > span {
        max-width: 240px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .tweet-footer {
        display: flex;
        min-height: 26px;
        justify-content: space-between !important;
        align-items: center !important;
        vertical-align: middle;

        span {
            font-size: 10px;
        }

        &span:first-of-type:nth-last-of-type(1) {
            margin-left: auto;
        }
    }
}

.no-bias {
    grid-column: auto;
}

.bias-selectors {
    font-weight: bold;

    label {
        padding: 0.1em 0.25em;
    }

    .left,
    .middle,
    .right {
        color: white;
    }

    .left {
        background-color: #0015dd;
    }

    .middle {
        background-color: #a013a5;
    }

    .right {
        background-color: #f82725;
    }
}
