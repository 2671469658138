.pagination-container {
    display: grid;
    align-items: center;
    place-content: center;
    grid-template-columns: 1fr 60px;
    max-height: 50px;

    .pagination {
        align-items: center;
        margin-bottom: 0 !important;
    }

    .per-page {
        justify-self: flex-end;
    }

    .page-link {
        z-index: unset !important;
    }

    .form-group {
        margin-bottom: 0 !important;
    }
}

.page-item.active .page-link {
    z-index: unset !important;
}
