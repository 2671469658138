.story-summary .aug-link {
    background-color: lightgreen;
}

.story-summary h5 {
    margin-bottom: 0;
}

.add-summary-modal-close {
    top: 0 !important;
}

.delete-summary-modal-close {
    display: none;
}
